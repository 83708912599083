/**
 * This mixin implements CRUD using API calls to Laravel
 */
export default {
  data() {
    return {
      apiQuery: "/",
      showDeleteDialog: false,
      loading: false,
      total: 0,
      items: [],
      options: {
        reload: 0,
      },
      meta: {},
      errorMessage: null,
      autoClearAfterAdd: true,
      autoCloseAfterEdit: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.loading = true;
        this.get(this.apiQuery, this.options)
          .then((data) => {
            if (data.hasOwnProperty.call(data, "data")) {
              this.items = data.data;
              this.total = data.total;
            } else {
              this.items = data;
              this.total = data.length;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.alert(error.message);
            this.loading = false;
            this.$emit("serverError", error);
          });
      },
      deep: true,
    },
  },

  methods: {
    reloadData() {
      this.$set(this.options, 'reload', ++this.options.reload);
    },

    onAdd() {
      this.$refs.editForm.addItem();
    },

    onShow(item) {
      this.$refs.showDialog.showUserDialog(item, this.items.indexOf(item));
    },

    onEdit(item) {
      this.$refs.editForm.editItem(item, this.items.indexOf(item));
    },

    onSaved(item, index) {
      if (index === -1) {
        this.items.push(item);
        this.total++;
        item.isNew = true;
        if (this.autoClearAfterAdd) {
          window.setTimeout(() => {
            this.$refs.editForm.clearForm();
          }, 2000);
        }
      } else {
        Object.assign(this.items[index], item);
      }
      this.$emit("updated");
      if (this.autoCloseAfterEdit) {
        window.setTimeout(() => {
          this.$refs.editForm.closeForm();
        }, 2500);
      } else {
        window.setTimeout(() => {
          this.$refs.editForm.savedItem = null;
        }, 2500);
      }
    },

    onDelete(item) {
      this.$refs.deleteConfirm.deleteItem(item, this.items.indexOf(item));
    },

    onDeleted(item, index) {
      this.items.splice(index, 1);
      this.total--;
      this.$emit("updated");
    },

    loadMeta(uri = "create/") {
      this.get(this.apiQuery + uri).then((data) => {
        this.meta = data;
      });
    },
  },
};
